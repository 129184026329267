import React from 'react';
import './App.css';

import Header from './components/Header/Header';
import Rate from './components/Rate/Rate';
import Footer from './components/Footer/Footer';
import {BrowserRouter as Router,  Switch, Route } from 'react-router-dom';
import About from './components/About/About';

class App extends React.Component {

  render(){
    return (
      <div className="wrapper"> 
      <div className="App content">
        <Router> 
          <Header />
          <div className="container">
            <main className="main"> 
                <Switch> 
                  <Route exact path={"/"} component={Rate} />
                  <Route  path={"/about"} component={About} />
                </Switch>
            </main>
          </div>
        </Router>
          <Footer />
      </div>
      </div>
    );
  }
}

export default App;
