import React, {Component} from 'react';
import '../Rate/Rate.css';

import Calc from '../Calc/Calc';

class Rate extends Component{

    state = {
        'date': '',
        'currencyRate': ''    
    };

    currency = ['USDEUR', 'USDGBP', 'USDPLN', 'USDRUB'];

    getRate = () => {
        fetch('http://apilayer.net/api/live?access_key=fb4ca19aade6fd7c3975d20dbf185cc3&currencies=EUR,GBP,PLN,RUB&source=USD&format=1')

            .then((data) => {
                
                return data.json();
            })
            .then((data) => {
                console.log(data, 'data');
                this.setState({
                    date: data.timestamp,
                });
                let result = {};
                for (let i = 0; i < this.currency.length; i++){
                    result [this.currency[i]] = data.quotes[this.currency[i]];
                }
                this.setState({
                    currencyRate: result,
                })
            })
    }

    componentDidMount(){
        this.getRate();
    }

    convertStampDate(unixtimestamp){
        // Months array
        const months_arr = ['Января','Февраля','Марта','Апреля','Мая','Июня','Июля','Августа','Сентября','Октября','Ноября','Декабря'];
        
        // Convert timestamp to milliseconds
        const date = new Date(unixtimestamp*1000);
        
        // Year
        const year = date.getFullYear();
        
        // Month
        const month = months_arr[date.getMonth()];
        
        // Day
        const day = date.getDate();
        
        // Hours
        // const hours = date.getHours();
        
        // Minutes
        // const minutes = "0" + date.getMinutes();
        
        // Seconds
        // const seconds = "0" + date.getSeconds();
        
        // Display date time in MM-dd-yyyy h:m:s format
        // const fulldate = month+' '+day+'-'+year+' '+hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
        
        // final date
        const convdataTime = ` ${day} ${month} ${year}` ;
        return convdataTime;
        }

    render() {        
        return(
            <div className="container-md">
                <h3>Курс <i className="fas fa-dollar-sign"></i> на {this.convertStampDate(this.state.date)}</h3>
                    <div className="row">
                            {Object.keys(this.state.currencyRate).map((keyName, i) => {
                                if(keyName === 'USDCAD'){
                                    return;
                                }
                            return (<div key={i} className="col-md-3">
                            <div className="card text-white bg-warning mb-3" style={{maxWidth: '18rem'}}>
                                <div className="card-header">{keyName.slice(3)}</div>
                                    <div className="card-body">
                                        <h5 className="card-title">{this.state.currencyRate[keyName].toFixed(2)} *</h5>
                                        <p className="card-text">* Стоимость 1 доллара.</p>
                                    </div>
                            </div>
                        </div>)}
                            )}
                    </div>
                    <Calc rate={this.state.currencyRate}/>
            </div>

        )
    }
}

export default Rate;