import React, {Component} from 'react';
import './Footer.css';


class Footer extends Component{

    render() {
        return (
            <footer id="footer" className="footer navbar-fixed-bottom">
                <div className="footer-bottom">
                    <div className="container">
                        <div className="flex-container">
                            <div className="flex-item">
                                <h1 className="footer-title"><a href="#">© 2022</a></h1>
                            </div>
                            <div className="flex-item">
                                <div className="module-bottom">
                                    <ul className="list-unstyled">
                                        <li><a href="#">Карта сайта</a></li>
                                        <li><a href="$">Контакты</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="flex-item">
                                <div className="module-bottom">
                                    <ul className="list-unstyled">
                                        <li><a href="#">Карта сайта</a></li>
                                        <li><a href="$">Контакты</a></li>
                                        <li><a href="$">О сервисе</a></li>
                                        <li><a href="$">Соглашение</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        ); 
    };
};

export default Footer;