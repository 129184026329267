import React, {Component} from 'react';
import '../About/About.css';

class About extends Component{
    render(){
        return(
            <div>
                <h2 className="about">О нас </h2>
            </div>

            
        );
    }
}

export default About;