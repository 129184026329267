import React, {Component} from 'react';
import './Calc.css';

class Calc extends Component{

    state={
        result: 0,
        resultReverse: 0
    }

    static getDerivedStateFromProps(props, state){
        return {
            rate: props.rate,
        }
    }

    calcRate = (e) => {
        e.preventDefault();
        let elements = e.target.elements;
        let countCurrency = elements['count-currency'].value;
        let typeCurrency = elements['type-currency'].value;

        this.setState({
            result: (countCurrency / this.state.rate[typeCurrency]).toFixed(2)
        })

    }

    calcRateReverse = (e) => {
        e.preventDefault();
        let elements = e.target.elements;
        let countCurrencyRev = +elements['count-currency-rev'].value;
        //let typeCurrencyRev = elements['type-currency-rev'].value;

        this.setState({
            resultReverse: (countCurrencyRev * this.state.rate['USDRUB']).toFixed(2)
        })
    }

    render(){
        return (
            <div className='calculator'>
                <h3>Калькулятор обмена</h3>
                <div className="block mb-5">
                    <div className="row">
                        <div className="col-sm-12">
                            <form onSubmit={this.calcRate}>
                                <div className="form-group">
                                    <label htmlFor="exampleInput1">У меня есть: </label>
                                        <input type="number" className="form-control-sm mr-3 ml-3" id="exampleInput1" defaultValue="150" name="count-currency"/>
                                    <select className="form-control-sm mr-3" id="exampleFormControlSelect1" name="type-currency"  data-size="5">
                                        {Object.keys(this.props.rate).map((keyName, i) => 
                                                (
                                                <option key={i} value={keyName}>{keyName.slice(3)}</option>
                                                )
                                    )}
                                    </select>
                                    <input type="submit" className="btn btn-primary" value="Расчитать"/>
                                </div>
                                <hr/>
                            </form>
                        </div>
                    </div>
                        <div>
                            <h3>Результат</h3>
                            <h4>USD {this.state.result}</h4>
                        </div>
                    <hr/>
                    <div className="row">
                        <div className="col-sm-12">
                            <form onSubmit={this.calcRateReverse}>
                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">У меня есть, сумма в долларах: </label>
                                        <input type="number" className="form-control-sm mr-3 ml-3" id="exampleInputPassword1" defaultValue="150" name="count-currency-rev"/>
                                    <input type="submit" className="btn btn-primary" value="Расчитать"/>
                                </div>
                                <hr/>
                            </form>
                        </div>
                    </div>
                    <div>
                        <h3>Результат</h3>
                        <h4>Руб {this.state.resultReverse}</h4>
                    </div>
                </div>
                
            </div>
        );
    }
};

export default Calc
