import React, {Component} from 'react';
import './Nav.css';

import {NavLink} from 'react-router-dom';


class Nav extends Component{
    render(){
        return (
            <div className="header-nav">
                <div className="container">
                    <nav>
                        <ul>
                            <li><NavLink exact to="/" activeStyle={{color:"white", fontWeight:"bold"}}>Главная</NavLink></li>
                            <li><NavLink to="/about" activeStyle={{color:"white", fontWeight:"bold"}}>О нас</NavLink></li>
                        </ul>
                    </nav>
                </div>
            </div>
        )
    }
}

export default Nav;